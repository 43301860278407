import React from 'react';

interface Props {
  subTitle: string;
}

const BuyerAccount: React.FC<Props> = (props: Props) => (
  <section className="row bg--blue-dark text-white py-6 p-md-6 rounded text-center">
    <div className="col">
      <h2 className="h2 color--white">Get started with Print Marketplace</h2>
      <h3 className="h4 color--white pb-4">{props.subTitle}</h3>
      <a
        href="https://theapsgroup.typeform.com/to/u8NWFprV"
        target="_blank"
        aria-label="Request a demo"
        className="btn btn-outline-light mt-3 mr-md-5 mr-3"
      >
        <span className="align-middle">request a demo</span>
      </a>
      <a
        href="https://printmarketplace.crowncommercial.gov.uk/auth/sign-up"
        aria-label="Create an account"
        target="_blank"
        className="btn bg--accent color--white mt-3"
      >
        <span className="align-middle">create account</span>
      </a>
    </div>
  </section>
);

export default BuyerAccount;
