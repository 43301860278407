import * as React from 'react';

// Components
import Benefits from '../components/benefits';
import Hero from '../components/hero';
import HighlightsNHS from '../components/highlights-nhs';
import HowItWorks from '../components/how-it-works';
import NavbarComponent from '../components/navbar';
import SEO from '../components/seo';
import TriedTested from '../components/tried-tested';
import Footer from '../components/footer';
import BuyerAccount from '../components/buyer-account';

// Data
import { footerContent } from '../data/footer';
import { footerPartnersNHSContent } from '../data/footer-partners-nhs';
import { loginMenuNHS } from '../data/menu-login-nhs';
import { buyerBenefitsNHS } from '../data/buyer-benefits-nhs';
import { buyerHowItWorksNHS } from '../data/buyer-how-it-works-nhs';
import { buyerTriedTestedNHS } from '../data/buyer-tried-tested-nhs';

// Layout
import Layout from '../layout/layout';

const NHSPage = () => (
  <Layout>
    <SEO
      title="Print Marketplace - Compare printing prices from trusted suppliers"
      description="The simple way to save on printing for the NHS and healthcare sector. Compare printing prices from trusted suppliers quickly and simply."
    />
    <section
      className="container-fluid home-hero-bg pb-3 pb-md-0"
      style={{
        backgroundImage:
          'url("https://cdn-dev.print-marketplace.co.uk/frontend/marketing/NHS-hero-bg.jpg")',
      }}
    >
      <div className="row">
        <div className="col-xl-12 col-md-12 mx-auto">
          <NavbarComponent
            current="buyer"
            loginMenu={loginMenuNHS}
            logo="https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/pm-nhs-logo-white-wide.svg"
            logoLink="/nhs"
          />
        </div>
      </div>
      <div className="container">
        <Hero
          jumbotron="The simple way to save on printing for the NHS and healthcare sector"
          lead="Compare printing prices from trusted suppliers – quickly and simply.
          Then easily track your print-projects right through to delivery."
          hasVideo={false}
          hasRegisterNow={false}
          hasRequestADemo
          hasCreateAccount
        />
      </div>
    </section>

    <section className="container-fluid pb-3">
      <div className="container home-hero-lift">
        <Benefits benefits={buyerBenefitsNHS} />
      </div>
    </section>

    <section className="container-fluid pb-6">
      <HowItWorks
        howItWorks={buyerHowItWorksNHS}
        intro="Simple, effective and quick – get the job done, whatever your sector"
        hasRegisterNow={false}
        hasRequestADemo={false}
        hasSeeHowItWorks
        video="https://player.vimeo.com/video/612656696?autoplay=1&loop=1&autopause=0"
        theme="secondary"
      />
    </section>

    <section className="container-fluid bg--white pb-6">
      <div className="container">
        <HighlightsNHS />

        <TriedTested triedTested={buyerTriedTestedNHS} />
      </div>
    </section>

    <section className="container-fluid bg--white pb-6">
      <div className="container">
        <BuyerAccount subTitle="To register please create your buyer account." />
      </div>
    </section>

    <Footer
      sections={footerContent}
      partners={footerPartnersNHSContent}
      year={`${new Date().getFullYear()}`}
    />
  </Layout>
);

export default NHSPage;
