import { CardContentModel } from '../models';

export const buyerTriedTestedNHS: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_prefered_suppliers_green.svg',
    title: 'Access to a wider selection of pre-approved suppliers',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_installed_green.svg',
    title: 'Nothing to install, no licence needed',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_commercial_platform_green.svg',
    title: 'Competitive and transparent commercial platform',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_prefered_suppliers_green.svg',
    title: 'Option to retain preferred local suppliers',
  },
];
