import React from 'react';
import { CardContentModel } from '../models';

const iconStyles = {
  width: '120px',
  height: '120px',
};

const TriedTestedItem: React.FC<CardContentModel> = ({ icon, title }) => (
  <div>
    <div className="p-4 mx-auto mb-2 col-6 col-md-6 col-lg-9">
      <img src={icon} alt={title} style={iconStyles} />
    </div>
    <p className="col-md-10 col-sm-7 col-7 mx-auto subtitle-2">{title}</p>
  </div>
);

export default TriedTestedItem;
