// eslint-disable-next-line no-unused-vars
import { FooterPartnerContent } from '../models';

export const footerPartnersNHSContent: FooterPartnerContent[] = [
  {
    name: 'aps',
    src: 'https://cdn-dev.print-marketplace.co.uk/frontend/logo_aps_white.svg',
  },
  {
    name: 'pmp',
    src: 'https://cdn-dev.print-marketplace.co.uk/frontend/logo_pmp_white.svg',
  },
  {
    name: 'ccs',
    src: 'https://cdn-dev.print-marketplace.co.uk/frontend/logo_ccs_white.svg',
  },
  {
    name: 'ypo',
    src: 'https://cdn-dev.print-marketplace.co.uk/frontend/logo_ypo_white.svg',
  },
  {
    name: 'nhs',
    src: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/logo_nhs_white.svg',
  },
];
