import React from 'react';
// eslint-disable-next-line no-unused-vars
import { CardContentModel } from '../models/card-content';
import TriedTestedItem from './tried-tested-item';

interface Props {
  triedTested: any;
}

const TriedTested: React.FC<Props> = ({ triedTested }: Props) => (
  <div>
    <header className="row text-center pt-6 pb-3">
      <div className="col">
        <h2>Tried and tested</h2>
      </div>
    </header>

    <div className="row">
      {triedTested.map((item: CardContentModel, index: number) => (
        <div className="col-lg-3 col-md-6 text-center" key={index}>
          <TriedTestedItem icon={item.icon} title={item.title} />
        </div>
      ))}
      <div className="col-lg-3 col-md-6 text-center" />
    </div>
  </div>
);

export default TriedTested;
