import { CardContentModel } from '../models';

export const buyerHowItWorksNHS: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_specify_green.svg',
    title: 'Specify your print and design requirements',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_choose_green.svg',
    title: 'Choose from a range of competitive prices',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/marketing/nhs/icon_circle_receive_green.svg',
    title: 'Prepare to receive your order',
  },
];
